@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@font-face {
    font-family: 'Crafting Lesson';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/CraftingLesson-2OnB8.f62b68ad.otf) format("opentype");
  }

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 14px;
  color: #1a1a1a;
}

p, h1, h2, h3, h4, h5, h6 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}


